import { ArrowBack as ArrowBackIcon, Save as SaveIcon } from "@mui/icons-material";
import { DevTool } from "@hookform/devtools";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfDay, format, isAfter, isBefore, startOfDay } from "date-fns";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { HUMAN_DATE_FORMAT } from "../../../../constant";
import { getCropTypesList } from "../../../../modules/crop-types";
import { CropType } from "../../../../modules/crop-types/shared/models/crop-type";
import {
  setFilterAction,
  getFilter,
  useDisableGlobalFilters,
} from "../../../../modules/filter";
import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { CommonLayout } from "../../../../shared/components/common-layout/common-layout";
import { RHFAutocompleteSimple } from "../../../../shared/components/react-hook-form-mui/autocomplete-simple";
import { getCropTypeById } from "../../../../modules/crop-types/store/crop-types.selector";
import { RHFInputHidden } from "../../../../shared/components/react-hook-form-mui/input-hidden";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { isEditingPage } from "../../../../shared/utils/is-editing-page";
import { isEmpty } from "../../../../shared/utils/is-empty";
import { useAppDispatch } from "../../../../store";
import { IFarmLandDto, formatFarmLand } from "../../../fields/shared/dtos/farm-land.dto";
import { ObservationPhotosUpload } from "../../components/observation-photos-upload/observation-photos-upload";
import { ObservationViolationsList } from "../../components/observation-violations-list/observation-violations-list";
import { Phenophase } from "../../../../modules/phenophases/shared/models/phenophases";
import { getList as getPhenoPhasesList } from "../../../../modules/phenophases/store";
import { IObservationFormData } from "../../shared/interfaces/observation-form-data";
import { Observation } from "../../shared/models/observation";
import { dtoToObservationFormData } from "../../shared/utils/dto-to-observation-form-data";
import { updateObservationFromFormData } from "../../shared/utils/update-observation-from-form-data";
import { getIsLoading } from "../../store/observation-editing.selector";
import {
  addObservationAction,
  fetchObservationByIdAction,
  updateObservationAction,
  uploadChosenPhotos,
} from "../../store/observation-editing.slice";
import { addNotificationAction } from "../../../../modules/notifications";
import { NotificationType } from "../../../../modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../../modules/notifications/shared/enums/notification-severity";
import { useCurrentFarmLands } from "../../../../modules/farm-lands/current-farm-lands.hook";
import { useSeasons } from "../../../../shared/hooks/seasons";
import { emptyGuid } from "../../../../shared/types";

export const ObservationsEditingForm = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const sourceFarmLandId = searchParams.get("fieldId");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const appFilterFarmId = useSelector(getFilter(FilterName.FarmId));
  // const [farmLands, setFarmLands] = useState<IFarmLandDto[]>([]);
  const cropTypes: CropType[] = useSelector(getCropTypesList);
  const phenoPhases: Phenophase[] = useSelector(getPhenoPhasesList);
  const isLoading = useSelector(getIsLoading);

  const {
    appFilterSeasonId,
    // currentSeasonResolved,
    seasonDates,
    // seasons,
    setSeasonDatesForSeasonId,
  } = useSeasons();

  const defaultValues = useMemo(
    () =>
      dtoToObservationFormData(new Observation().setFarmLandId(sourceFarmLandId).asDto),
    [sourceFarmLandId]
  );
  const [editedModel, setEditedModel] = useState<null | Observation>(null);

  const rhfMethods = useForm<IObservationFormData>({
    mode: "all",
    reValidateMode: "onBlur",
    defaultValues,
  });
  const { setValue, reset, trigger } = rhfMethods;
  const watchFields = rhfMethods.watch();

  useDisableGlobalFilters();

  // Utility getters

  const isEditingObservation = useMemo(() => isEditingPage(id), [id]);

  const { farmLands_emptyWhileFetching: farmLands } = useCurrentFarmLands();

  const onObservationFetched = useCallback(
    async (observationFetched: Observation) => {
      setEditedModel(observationFetched); // save full model to later updates
      reset(dtoToObservationFormData(observationFetched.asDto));
      await trigger();

      // setValue("farmId", `${appFilterFarmId}`);
      // setValue("seasonId", appFilterSeasonId);

      if (observationFetched.seasonId) {
        setSeasonDatesForSeasonId(observationFetched.seasonId);

        if (appFilterSeasonId !== observationFetched.seasonId) {
          // upper FARM dropdown is reset to whatever techOp.seasonId
          // (fixing backend bug when a new TO is assigned to another season)
          dispatch(
            setFilterAction({
              filter: FilterName.SeasonId,
              value: observationFetched.seasonId,
            })
          );
        }
      }

      // TODO FIXME how appFilterFarmId is now a number?? is that a long64 with GUID inside????
      if (`${appFilterFarmId}` !== observationFetched.farmId) {
        // upper FARM dropdown is reset to whatever techOp.farmId
        // (fixing backend bug when a new TO is assigned to another farm / field)
        dispatch(
          setFilterAction({ filter: FilterName.FarmId, value: observationFetched.farmId })
        );
      }
    },
    [
      appFilterFarmId,
      appFilterSeasonId,
      dispatch,
      reset,
      setSeasonDatesForSeasonId,
      trigger,
    ]
  ); // all dependencies go here, instead of useEffect() below

  useEffect(() => {
    // useCurrentFarmLands() watches for UPPER FILTERS and loads new farmLands for:
    // const appFilterFarmId = useSelector(getFilter(FilterName.FarmId));
    // const appFilterSeasonId = useSelector(getFilter(FilterName.SeasonId));
    // loadFarmLands();

    // id already included in isEditing... but ts compiler doesn't see it
    if (id && isEditingObservation) {
      dispatch(fetchObservationByIdAction(id))
        .unwrap()
        .then((model: Observation) => {
          onObservationFetched(model);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    isEditingObservation,
    dispatch,
    // NEVER_MENTION_USE_CALLBACKS_WITH_PARAMETERS_IN_EFFECT onObservationFetched
  ]);

  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = useCallback(
    async (data) => {
      // TODO: shouldn't call if errors exists (remove and check it)
      if (!isEmpty(rhfMethods.formState.errors)) {
        return;
      }

      let newModel = new Observation(data.id);

      if (isEditingObservation) {
        if (editedModel) {
          newModel.updateFromDto(editedModel.asDto);
        }
      } else {
        if (!data.seasonId) {
          // otherwise
          // statusCode: 422
          // message: "The `ObservationV2` instance is not valid. Details: `seasonId` can't be blank (value: \"\")."
          data.seasonId = appFilterSeasonId ?? "SELECT_SEASON_FROM_UPPER_DROPDOWN";
        }
        if (!data.farmId) {
          data.farmId = appFilterFarmId ?? "SELECT_FARM_FROM_UPPER_DROPDOWN";
        }
      }

      updateObservationFromFormData(newModel, data);
      newModel.setAppUserId(localStorage.getItem("user_id") as string);

      const beforeEditingHadViolations = editedModel && editedModel.violations.length > 0;
      const afterEditingViolationsRemoved = newModel.violations === undefined;
      if (
        isEditingObservation &&
        beforeEditingHadViolations &&
        afterEditingViolationsRemoved
      ) {
        // 1. <ObservationViolationsList>.removeViolation выставил .violations в undefined
        // 2. бэкенд проигнорирует undefined и не очистит у себя violations
        // 3. поэтому выставляем в пустой массив, исправляя поведение хука useFieldArray()
        // 4. чтобы не вводить ни DTO, ни сервис в "курс дела", я выставляю всё ЗДЕСЬ
        // 5. условия вынесены в boolean переменные и вербально читаемы
        // 6. бэкенд выполняет команду "всё стереть". ошибочный тест-кейс исправлен
        newModel.setViolations([]); // [] will remove all on the backend
      }

      try {
        setIsSaving(true);

        newModel = await dispatch(
          isEditingObservation
            ? updateObservationAction(newModel)
            : addObservationAction(newModel)
        ).unwrap();

        rhfMethods.reset(dtoToObservationFormData(newModel.asDto));

        await dispatch(uploadChosenPhotos()).unwrap();
        newModel.completeReport();

        // who needs this second PATCH?
        // newModel = await dispatch(updateObservationAction(newModel)).unwrap();

        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Success,
            message: `Отчет осмотра поля ${
              isEditingObservation ? "сохранен" : "добавлен"
            }`,
          })
        );

        if (isEditingObservation) {
          return;
        }

        navigate(`../${newModel.id}`, {
          relative: "path", // navigate relative to path (not router tree)
          replace: true,
        });
      } catch (err) {
        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Error,
            message:
              "При сохранении отчета осмотра поля произошла ошибка, попробуйте позже",
            error: err,
          })
        );
      } finally {
        setIsSaving(false);
      }
    },
    [
      dispatch,
      navigate,
      isEditingObservation,
      rhfMethods,
      editedModel,
      appFilterFarmId,
      appFilterSeasonId,
      setIsSaving,
    ]
  );

  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    (async () => {
      if (!farmLands.length || !watchFields.farmLandId) {
        return;
      }

      const farmLand = findModelByProperty(farmLands, watchFields.farmLandId);

      if (
        rhfMethods.formState.touchedFields.farmLandId ||
        rhfMethods.formState.dirtyFields.farmLandId
      ) {
        setValue("farmLandId", rhfMethods.getValues("farmLandId"), { shouldTouch: true });
        setValue("cropTypeId", farmLand?.cropTypeId || "");
        setValue("phenoPhaseId", "");
        setValue("violations", []);
      }
    })();
    // eslint-disable-next-line
  }, [setValue, farmLands, watchFields.farmLandId]);

  // const chosenFarmLandCropName = useMemo(() => {
  //   const chosenFarmLand = findModelByProperty(farmLands, watchFields.farmLandId);
  //   const chosenFarmLandCrop = findModelByProperty(cropTypes, chosenFarmLand?.cropTypeId);
  //   return chosenFarmLandCrop?.name || "";
  // }, [cropTypes, farmLands, watchFields.farmLandId]);

  const farmLandFromObservation = useMemo(() => {
    if (!farmLands) {
      return;
    }
    if (farmLands.length === 0) {
      return;
    }
    if (watchFields.farmLandId === "") {
      return;
    }
    const farmLand = farmLands.find((x) => x.id === watchFields.farmLandId);
    return farmLand;
  }, [
    farmLands,
    // watchFields.farmLandId changes when:
    // 1) FARM selector gets loaded
    // 2) "Добавить в №3" => F5
    // 3) by DIRECT LINK sent via messenger to "Добавить в №3" page
    watchFields.farmLandId,
  ]);

  const farmLandCropNameFromObservation: string | undefined = useMemo(() => {
    if (farmLandFromObservation === undefined) {
      return undefined;
    }
    const ret = cropTypes.find((x) => x.id === farmLandFromObservation.cropTypeId);
    return ret?.name;
  }, [cropTypes, farmLandFromObservation]);

  const availablePhenoPhases = useMemo(() => {
    if (!watchFields.farmLandId || !phenoPhases.length) {
      return [];
    }
    const chosenFarmLand = farmLands.find((x) => x.id === watchFields.farmLandId);
    const phenoPhasesForCropType = phenoPhases.filter(
      (item) => item.cropTypeId === chosenFarmLand?.cropTypeId
    );
    if (phenoPhasesForCropType.length > 0) {
      return phenoPhasesForCropType;
    }

    // not empty on dev: https://develop.ekocrop.ekoniva-apk.dev/observations/editing/new
    const phenoPhasesForEmptyGuid = phenoPhases.filter(
      (item) => item.cropTypeId === emptyGuid
    );
    return phenoPhasesForEmptyGuid;
  }, [farmLands, phenoPhases, watchFields.farmLandId]);

  // observationDate TODO: move it in single component
  const minDate = useMemo(
    () => new Date(seasonDates?.startedAt || 0),
    [seasonDates?.startedAt]
  );
  const maxDate = useMemo(
    () =>
      seasonDates?.finishedAt && seasonDates.finishedAt < new Date().getTime()
        ? new Date(seasonDates.finishedAt)
        : new Date(),
    [seasonDates?.finishedAt]
  );

  // TODO: try to substitute this logic (it needs to apply min/max validation bounds)
  useEffect(() => {
    rhfMethods.trigger("observationDate");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasonDates, watchFields.observationDate]);

  const hasErrors = !isEmpty(rhfMethods.formState.errors); // = !rhfMethods.formState.isValid
  const changedByUser = rhfMethods.formState.isDirty;
  const isSubmitDisabled = !changedByUser || hasErrors || isLoading || isSaving;

  const cropTypesById = useSelector(getCropTypeById);

  return (
    <CommonLayout title={"Отчёты осмотров"}>
      <Paper>
        <FormProvider {...rhfMethods}>
          <form onSubmit={rhfMethods.handleSubmit(onSubmit)}>
            {/* hidding in prode mode already included in component */}
            <DevTool control={rhfMethods.control} />
            <RHFInputHidden name="id" defaultValue={""} />
            <RHFInputHidden name="farmId" defaultValue={""} />
            <RHFInputHidden name="seasonId" defaultValue={""} />
            <RHFInputHidden name="cropTypeId" defaultValue={""} />

            <List>
              <ListItem>
                <Grid container={true} spacing={2}>
                  {/* <Grid item={true} xs={6}>
                    <RHFAutocomplete<IFarmLandDto>
                      name="farmLandId"
                      rules={{ required: true }}
                      renderValue={(value) => findModelByProperty(farmLands, value)}
                      AutocompleteProps={{
                        disabled:
                          !farmLands.length ||
                          Boolean(sourceFarmLandId) ||
                          isEditingObservation,
                        options: farmLands,
                        noOptionsText: "В хозяйстве нет полей",
                      }}
                      TextFieldProps={{
                        required: true,
                        label: "Поле",
                      }}
                    />
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Box
                      sx={{
                        pt: 3,
                      }}
                    >
                      {chosenFarmLandCropName}
                    </Box>
                  </Grid> */}

                  <Grid item={true} xs={6}>
                    <RHFAutocompleteSimple<IFarmLandDto>
                      name="farmLandId"
                      rules={{ required: true }}
                      defaultValue={defaultValues.farmLandId || ""}
                      optionGuidToObject={(optionGuid: string) => {
                        if (!farmLands) {
                          return undefined;
                        }
                        const ret = farmLands.find((f) => f.id === optionGuid);
                        return ret;
                      }}
                      disabled={
                        !farmLands.length ||
                        Boolean(sourceFarmLandId) ||
                        isEditingObservation
                      }
                      options={farmLands || []}
                      getOptionLabel={formatFarmLand}
                      noOptionsText="В хозяйстве нет полей"
                      textFieldProps={{
                        label: "Поле",
                        required: true,
                      }}
                    />

                    {farmLandCropNameFromObservation === undefined &&
                    watchFields.farmLandId &&
                    watchFields.farmLandId.length > 0 ? (
                      <Box pt={3}>
                        Поля {watchFields.farmLandId} не существует в сезоне
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item={true} xs={6} />

                  <Grid item={true} xs={6}>
                    {/* TODO: now we have a rule if page is add then we must fill phenoPhase, if edit, then not (need to fix it) */}
                    <RHFAutocompleteSimple<Phenophase>
                      name="phenoPhaseId"
                      rules={isEditingObservation ? {} : { required: true }}
                      getOptionLabel={(x: Phenophase) => {
                        let label = x.name;
                        const cropTypeFound = cropTypesById?.get(x.cropTypeId);
                        if (cropTypeFound) {
                          label = `${cropTypeFound.name}: ${label}`;
                        } else {
                          label = `(культура не найдена): ${label}`;
                        }
                        return label;
                      }}
                      optionGuidToObject={(optionGuid: string) => {
                        const ret = availablePhenoPhases.find((f) => f.id === optionGuid);
                        return ret;
                      }}
                      disabled={!watchFields.farmLandId}
                      options={availablePhenoPhases}
                      noOptionsText={"Нет стадий роста"}
                      textFieldProps={{
                        required: !isEditingObservation,
                        label: "Стадия роста",
                      }}
                    />
                  </Grid>

                  <Grid item={true} xs={6}>
                    <Controller
                      control={rhfMethods.control}
                      name={"observationDate"}
                      rules={{
                        required: true,
                        validate: (date: Date | null) => {
                          if (!date) return true;

                          if (isBefore(date, startOfDay(minDate))) {
                            return `Дата окончания работы не должна быть раньше ${format(
                              minDate,
                              HUMAN_DATE_FORMAT
                            )}`;
                          }

                          if (isAfter(date, endOfDay(maxDate))) {
                            return `Дата окончания работы не должна быть позднее ${format(
                              maxDate,
                              HUMAN_DATE_FORMAT
                            )}`;
                          }

                          return true;
                        },
                      }}
                      render={({ fieldState: { error }, field }) => (
                        <DatePicker
                          {...field}
                          label={"Дата отчета"}
                          format={HUMAN_DATE_FORMAT}
                          minDate={minDate}
                          maxDate={maxDate}
                          slotProps={{
                            textField: {
                              variant: "standard",
                              fullWidth: true,
                              error: !!error,
                              helperText: error ? error.message : "",
                              InputLabelProps: {
                                required: true,
                              },
                              InputProps: {
                                required: true,
                              },
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem>
                <RHFTextField
                  name={"comment"}
                  TextFieldProps={{
                    label: "Комментарий",
                  }}
                />
              </ListItem>

              <ObservationViolationsList />

              <ListItem>
                <Typography variant={"h5"}>Фотографии отчета</Typography>
              </ListItem>

              <Box
                sx={{
                  px: 2,
                  py: 1,
                }}
              >
                <ObservationPhotosUpload />
              </Box>
            </List>

            <Divider />

            <Box
              sx={{
                p: 1,
              }}
            >
              <Grid container={true} justifyContent={"center"} spacing={2}>
                <Grid item={true}>
                  <Button
                    color={"primary"}
                    variant={"outlined"}
                    onClick={onCancel}
                    startIcon={<ArrowBackIcon />}
                  >
                    Назад
                  </Button>
                </Grid>

                <Grid item={true}>
                  <Button
                    type="submit"
                    color={"primary"}
                    variant={"contained"}
                    disabled={isSubmitDisabled}
                    startIcon={
                      isLoading || isSaving ? (
                        <CircularProgress size={20} />
                      ) : (
                        <SaveIcon />
                      )
                    }
                  >
                    {isEditingObservation ? "Сохранить" : "Добавить"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>
      </Paper>
    </CommonLayout>
  );
};
