import {
  ArrowBack as ArrowBackIcon,
  Queue as QueueIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import { DevTool } from "@hookform/devtools";
import { Box, Button, CircularProgress, Divider, Grid } from "@mui/material";
import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FilterName } from "../../../../modules/filter/shared/enums/filter-name";
import { getFilter } from "../../../../modules/filter/store/filters.selector";
import { getInfoTechOperationSubGroups } from "../../../../modules/info-data";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import { RHFInputHidden } from "../../../../shared/components/react-hook-form-mui/input-hidden";
import { isEditingPage } from "../../../../shared/utils/is-editing-page";
import { useAppDispatch } from "../../../../store";
import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { TechOperation } from "../../shared/models/tech-operation";
// import { setTechOperationAssets } from "../../store/tech-operation-editing.slice";
import { addNotificationAction } from "../../../../modules/notifications";
import { NotificationType } from "../../../../modules/notifications/shared/enums/notification-type";
import { NotificationSeverity } from "../../../../modules/notifications/shared/enums/notification-severity";
import { PATHS } from "../../../../constant";
import { RhfTechOpEditFormUtils } from "./use-form-utils";
import {
  useAddTechOpMutation,
  useFetchTechOpQueryState,
  useUpdateTechOpMutation,
} from "../../store/techop.rtkq";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useAutoHide } from "../../../../shared/hooks/autohide";
import {
  useLazyFetchAreaHistory_forOpNoQuery,
  useLazyFetchOpNumbers_forGroupQuery,
} from "../../store/techop-related.rtkq";
import {
  asTechOperationAgregatedsV2Error,
  isTechOperationAgregatedsV2Error,
} from "../../store/techop.rtkq.types";
import {
  asLoopbackError,
  isLoopbackError,
} from "../../../../modules/tech-assets/store/techop-assets.rtkq.types";
import { devlog } from "../../../../shared/utils/log";
import { useSeasons } from "../../../../shared/hooks/seasons";

const OPERATION_NUMBER_NEW = -1;

export const TechOpEditFormSubmit = ({
  techOpId,
  onSubmitted,
  rhfSingleton,
  disabled,
  children,
}: PropsWithChildren<{
  techOpId: string | undefined;
  onSubmitted?: () => void;
  rhfSingleton: RhfTechOpEditFormUtils;
  disabled?: boolean;
}>): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { isFetching: isFetchingTechOp, error: errorFetchOp } =
    useFetchTechOpQueryState(skipToken);

  const appFilterFarmId = useSelector(getFilter(FilterName.FarmId));

  const techOperationsSubGroups = useSelector(getInfoTechOperationSubGroups);

  const {
    resetFieldsBelowSubGroup,
    resetFieldsBelowGroup_forAddNewTechop,
    setDefaultValues,
    rhfMethods,
    operationNumber,
    nextOperationNumber,
  } = rhfSingleton;
  const { reset, control, formState } = rhfMethods;
  const watchFields = rhfMethods.watch();

  const isExistingTechOperation = useMemo(() => isEditingPage(techOpId), [techOpId]);
  const isNew = useMemo(() => techOpId === PATHS.EDIT_NEW_PAGE, [techOpId]);

  const { autoHideJsx, autoHide } = useAutoHide();

  const [addTechOp, { isLoading: isAdding }] = useAddTechOpMutation();
  const [updateTechOp, { isLoading: isUpdating }] = useUpdateTechOpMutation();

  // former "ref is set in <TechOpEditFormContent/>, consumed in <TechOpEditFormSubmit/>"
  const [fetchAreaHistory_forOpNo_trigger] = useLazyFetchAreaHistory_forOpNoQuery();
  const [
    fetchOpNumbers_forGroup_trigger,
    // {
    //   // data: opNumbersFetched,
    //   error: errorFetchOpNumbers_forGroup,
    // },
  ] = useLazyFetchOpNumbers_forGroupQuery();

  const onSubmit = useCallback(
    async (data: ITechOperationFormData) => {
      const newDto = new TechOperation(data.id);
      if (!isExistingTechOperation) {
        // just like it was in export const addTechOperationAction = createAsyncThunk
        // tech-operations-editing.slice.ts commit 58cb942f8596eaa9c91c12d2154245395b2e62c9
        newDto.resetCreatedOnClientAt(); // for addTechOp(newDto) below
      }
      newDto.updateFromFormData(data);
      newDto.setAppUserId(localStorage.getItem("user_id") as string);
      newDto.updateEachAsset(appFilterFarmId?.toString() ?? "", newDto.id);

      if (newDto.operationNumber === OPERATION_NUMBER_NEW) {
        if (typeof nextOperationNumber === "undefined") {
          throw new Error("nextOperationNumber should be set");
        }
        newDto.operationNumber = nextOperationNumber;
      }

      try {
        const techOpModelMutation = isExistingTechOperation
          ? updateTechOp(newDto)
          : addTechOp(newDto);
        const techOpModel: TechOperation = await techOpModelMutation.unwrap();

        const formData = techOpModel.asFormData;

        resetFieldsBelowSubGroup();
        if (isExistingTechOperation) {
          setDefaultValues(formData);

          if (techOpModel.operationNumber) {
            // after editing/new + BROWSER "BACK", next editing/new will have correct field history
            // fetchAreaHistory_forOpNumberSelected_absorbFieldSize(
            //   techOpModel.operationNumber,
            //   techOpModel.id
            // );
            // fetchAreaHistory_forOpNumberSelected_absorbFieldSize_ref.current?.(
            //   techOpModel.operationNumber,
            //   techOpModel.id
            // );
            fetchAreaHistory_forOpNo_trigger({
              farmLandId: appFilterFarmId || "",
              operationNumber: techOpModel.operationNumber,
              techOperationGroupId: techOpModel.techOperationGroupId,
            });
          }
        } else {
          // re-read all opNumbers to fill the new added OpNumber into dropdown
          // fetchCurrentOperationNumbersForSubGroup();

          const newBackendOpNumber = techOpModel.operationNumber;
          if (newBackendOpNumber) {
            // after editing/new + BROWSER "BACK", next editing/new will have correct field history
            // fetchAreaHistory_forOpNumberSelected_absorbFieldSize(newBackendOpNumber, techOpModel.id);
            // fetchAreaHistory_forOpNumberSelected_absorbFieldSize_ref.current?.(
            //   newBackendOpNumber,
            //   techOpModel.id
            // );
            fetchAreaHistory_forOpNo_trigger({
              farmLandId: appFilterFarmId || "",
              operationNumber: newBackendOpNumber,
              techOperationGroupId: techOpModel.techOperationGroupId,
            });

            // backend has assigned new OpNumber => re-read OpNumbers list again to fill DropDown
            // fetchOpNumbers_forSubGroup_ref.current?.();
            // fetchOpNumbers_forSubGroup_ref.current?.();
            fetchOpNumbers_forGroup_trigger({
              farmLandId: appFilterFarmId || "",
              techOperationGroupId: techOpModel.techOperationGroupId,
            });
          }

          navigate(`../${techOpModel.id}`, { relative: "path", replace: false });
        }

        const msg = `Тех. операция ${
          isExistingTechOperation ? "сохранена" : "добавлена"
        }`;
        autoHide(msg, false);
        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Success,
            message: msg,
          })
        );
      } catch (ex) {
        devlog(`onSubmit():`, ex);

        let err = "UNKNOWN_ERROR_DTO";
        if (typeof ex === "string") {
          err = ex;
        } else if (isTechOperationAgregatedsV2Error(ex)) {
          err = asTechOperationAgregatedsV2Error(ex) || err;
        } else if (isLoopbackError(ex)) {
          err = asLoopbackError(ex) || err;
        }

        autoHide(err as string, true, 10000);

        const message = `При сохранении тех. операции произошла ошибка, попробуйте позже`;
        dispatch(
          addNotificationAction({
            type: NotificationType.Snack,
            severity: NotificationSeverity.Error,
            message: message,
            error: err, // NOT PRINTED ANYWHERE???
          })
        );
      }
      onSubmitted?.();
    },
    [
      appFilterFarmId,
      dispatch,
      navigate,
      isExistingTechOperation,
      nextOperationNumber,
      updateTechOp,
      addTechOp,
      resetFieldsBelowSubGroup,
      setDefaultValues,
      fetchAreaHistory_forOpNo_trigger,
      fetchOpNumbers_forGroup_trigger,
      onSubmitted,
      autoHide,
    ]
  );

  const onCancel = useCallback(() => {
    reset();
    // invalidate useFetchTechAssets_forTechOpIdQuery() cache?
    // dispatch(setTechOperationAssets([]));
    navigate(-1);
  }, [
    navigate,
    reset,
    // dispatch
  ]);

  const onAppendToSameOperationNumber = useCallback(() => {
    resetFieldsBelowSubGroup();
    const intoSameOpNumber =
      `farmLandId=${watchFields.farmLandId}` +
      `&techOperationGroupId=${watchFields.techOperationGroupId}` +
      `&techOperationSubGroupId=${watchFields.techOperationSubGroupId}` +
      `&operationNumber=${operationNumber}`;
    const url = `../new?${intoSameOpNumber}`;
    navigate(url, { relative: "path", replace: false });
  }, [resetFieldsBelowSubGroup, navigate, watchFields, operationNumber]);

  const currentTechOperationSubGroup = useMemo(() => {
    return findModelByProperty(
      techOperationsSubGroups,
      watchFields.techOperationSubGroupId
    );
  }, [techOperationsSubGroups, watchFields.techOperationSubGroupId]);

  const isAssetRequired = useMemo(() => {
    if (!watchFields.techOperationSubGroupId) {
      return false;
    }
    if (!currentTechOperationSubGroup) {
      return false;
    }
    const ret =
      currentTechOperationSubGroup.usesChemicals ||
      currentTechOperationSubGroup.usesFertilisers ||
      currentTechOperationSubGroup.usesSeeds;
    return ret;
  }, [currentTechOperationSubGroup, watchFields.techOperationSubGroupId]);

  // https://react-hook-form.com/docs/useform/formstate
  // "Read the formState before render to subscribe the form state through the Proxy"
  // const { isValid, errors, isDirty, dirtyFields } = formState;
  const { isValid } = formState;

  const isSaving = useMemo(() => isUpdating || isAdding, [isUpdating, isAdding]);

  const isSubmitEnabled = useMemo(() => {
    if (isSaving || isFetchingTechOp) {
      return false;
    }
    // eslint-disable-next-line no-console
    // console.log(`isSubmitEnabled: isValid=`, isValid);
    if (isAssetRequired) {
      const mandatoryAssetsFilled = watchFields.assets?.length || 0;
      const assetsValid = mandatoryAssetsFilled > 0;
      return isValid && assetsValid;
    }
    return isValid;
  }, [isSaving, isFetchingTechOp, isValid, isAssetRequired, watchFields.assets]);

  const onAddNewTechOp = useCallback(() => {
    // moved to useEffect(() => { if (isExistingTechOperation === false)...}
    // setTechOpFetched(undefined);
    // setDefaultValues(getDefaultValues_forCurrentFarmLand());
    resetFieldsBelowGroup_forAddNewTechop();
    const url = `../new`;
    navigate(url, { relative: "path", replace: false });
  }, [
    // getDefaultValues_forCurrentFarmLand,
    resetFieldsBelowGroup_forAddNewTechop,
    navigate,
  ]);

  const { appFilterSeasonResolved } = useSeasons();

  const canEditTechopsForInactiveSeason = useMemo(() => {
    if (appFilterSeasonResolved?.isEditable === undefined) {
      // 1) пока не загрузился /api/Seasons,
      // 2) если бэкенд не предоставил .isEditable
      // то считаем что сезон открыт
      return true; // можно сохранять открытую техоперацию
    }
    return appFilterSeasonResolved.isEditable;
  }, [appFilterSeasonResolved]);

  return (
    <FormProvider {...rhfMethods}>
      <form onSubmit={rhfMethods.handleSubmit(onSubmit)}>
        <DevTool control={rhfMethods.control} />
        <RHFInputHidden name="id" defaultValue={""} />
        <RHFInputHidden name="farmId" defaultValue={""} />
        <RHFInputHidden name="seasonId" defaultValue={""} />
        <RHFInputHidden name="cropTypeId" defaultValue={""} />

        {children}

        <Divider />

        <FormButtons savedSuccessOrError={autoHideJsx}>
          <Button
            color={"primary"}
            variant={"outlined"}
            onClick={onCancel}
            startIcon={<ArrowBackIcon />}
          >
            Назад
          </Button>

          {!isNew && canEditTechopsForInactiveSeason && (
            <>
              <Button
                color={"primary"}
                variant={"outlined"}
                onClick={onAppendToSameOperationNumber}
                startIcon={<QueueIcon />}
                sx={{ ml: 4 }}
              >
                Добавить в №{operationNumber}
              </Button>

              <Button
                color={"primary"}
                variant={"outlined"}
                onClick={onAddNewTechOp}
                startIcon={<QueueIcon />}
                sx={{ ml: 4 }}
              >
                Добавить
              </Button>
            </>
          )}

          <Button
            type="submit"
            color={"primary"}
            variant={"contained"}
            disabled={
              !isSubmitEnabled ||
              disabled ||
              !canEditTechopsForInactiveSeason ||
              !!errorFetchOp
            }
            startIcon={
              isFetchingTechOp || isUpdating || isAdding ? (
                <CircularProgress size={20} />
              ) : (
                <SaveIcon />
              )
            }
            sx={{ mx: 4 }}
          >
            {!canEditTechopsForInactiveSeason
              ? "Неактивный сезон"
              : isNew
              ? "Добавить"
              : "Сохранить"}
          </Button>
        </FormButtons>

        {disabled && (
          <Box
            p={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ color: "red" }}
          >
            Запрещено добавлять тех.операцию в поле-предшественника (поле было объединено
            \ разъединено в текущем сезоне)
          </Box>
        )}
      </form>

      <DevTool control={control} />
    </FormProvider>
  );
};

function FormButtons({
  savedSuccessOrError,
  children,
}: PropsWithChildren<{ savedSuccessOrError?: JSX.Element }>): JSX.Element {
  if (!savedSuccessOrError) {
    return (
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={12}>
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid item>{children}</Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} sx={{ p: 1 }}>
      <Grid item xs={7}>
        <Grid container alignItems={"center"} justifyContent={"end"}>
          <Grid item>{children}</Grid>
        </Grid>
      </Grid>

      {savedSuccessOrError ? (
        <Grid item xs={5}>
          {savedSuccessOrError}
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
}
