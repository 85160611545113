import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IFilterFormData } from "../../../shared/interfaces/filter-form-data";

export const OnlyActiveRecordsField = (): React.JSX.Element => {
  const { control } = useFormContext<IFilterFormData>();

  return (
    <Controller
      name={"isActive"}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={<Checkbox {...field} checked={field.value} />}
          label={"Только сформированные акты"}
        />
      )}
    />
  );
};
