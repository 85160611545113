import { Grid } from "@mui/material";
import Formula from "@sideway/formula";
import { isEqual, round } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  getInfoTechOperationSubGroups,
  getTechOperationsScalars,
} from "../../../../modules/info-data/info-data-selectors";
import { ITechOperationScalar } from "../../../../modules/info-data/shared/interfaces/tech-operation-scalar";
import { SpinnerCenter } from "../../../../shared/components/SpinnerCenter";
import { RHFAutocomplete } from "../../../../shared/components/react-hook-form-mui/autocomplete";
import { RHFInputHidden } from "../../../../shared/components/react-hook-form-mui/input-hidden";
import { RHFTextField } from "../../../../shared/components/react-hook-form-mui/textfield";
import { findModelByProperty } from "../../../../shared/utils/get-collection-item-by-field";
import {
  ITechOperationArrayParamDetailsDto,
  ITechOperationArrayParamDto,
  ITechOperationParamWithArrayParamsDto,
} from "../../shared/dtos/tech-operation-param.dto";
import { useLazyFetchSubGroupParams_forSubGroupQuery } from "../../store/techop-related.rtkq";
import { deverror, devlog } from "../../../../shared/utils/log";
import { ITechOperationFormData } from "../../shared/interfaces/tech-operation-form-data";
import { useHumidityImpurityCalculator } from "./use-humidity-impurity-calculator";

interface IScalarFormulation {
  [key: string]: {
    alias: string;
    formula: Formula.Parser<number>;
  };
}

type AvailableScalars = {
  // [key: ITechOperationParamWithArrayParamsDto["id"]]: ITechOperationScalar;
  [key: number]: ITechOperationScalar;
};

type ScalarArguments = Record<string, number | string>;

const computedUnits: Record<string, string> = {
  impurity: " %", // "Сорность, %""
  mass_harvested_products: " т", // "Валовый сбор, т"
  yield_harvest: " кг/га", // "Урожайность, кг/га"
  confirm_yield: " кг/га", // "Зачётная урожайность, кг/га"
  humidity_during_cleaning: " %", // "Влажность при уборке, %"
  mass_harvested_products_reduced_to_standard: " т", // "Валовый сбор, приведенный к базовым показателям, т"
  gross_collection_of_dry_matter: " т", // "Валовый сбор сухого вещества, т"
  dry_mass_yield: " кг/га", // "Урожайность сухого вещества, кг/га"
  dry_mass: " %", // "% С.В. при уборке, %"
};

export const TechOperationEditingSubgroupParams = ({
  isExistingTechOperation,
  disabled,
}: {
  isExistingTechOperation: boolean;
  disabled?: boolean;
}): JSX.Element => {
  const rhfMethods = useFormContext<ITechOperationFormData>();
  const watchFields = rhfMethods.watch();
  const { setValue } = rhfMethods;

  const techOperationsScalars = useSelector(getTechOperationsScalars);
  const [availableScalars, setAvailableScalars] = useState<AvailableScalars>({});
  const [scalarsFormulations, setScalarsFormulations] = useState<IScalarFormulation>({});
  const [scalarsArguments, setScalarsArguments] = useState<ScalarArguments>({});

  const [
    fetchSubGroupParams_trigger,
    { isFetching: isLoadingSubGroupParams, data: subGroupParams },
  ] = useLazyFetchSubGroupParams_forSubGroupQuery();
  const fetchSubGroupParams = useCallback(
    (techOperationSubGroupId: string) => {
      if (isLoadingSubGroupParams) {
        return;
      }
      fetchSubGroupParams_trigger(techOperationSubGroupId).unwrap();
      // .then((subGroupParamsFetched: ITechOperationParamWithArrayParamsDto[]) => {
      //   setSubGroupParams(subGroupParamsFetched);
      // })
    },
    [isLoadingSubGroupParams, fetchSubGroupParams_trigger]
  );

  const [subGroupParamsSorted, setSubGroupParamsSorted] = useState<
    ITechOperationParamWithArrayParamsDto[]
  >([]);

  const [arrayParamDetails, setArrayParamDetails] = useState<
    ITechOperationArrayParamDetailsDto[]
  >([]);
  const [subTypeParamTitle, setSubTypeParamTitle] = useState<string>("");

  useEffect(() => {
    if (!subGroupParams) {
      return; // undefined => NOT_YET_FETCHED
    }

    const paramsSorted = sortParams(subGroupParams, availableScalars);
    setSubGroupParamsSorted(paramsSorted);

    const formulations = extractFormulations(paramsSorted, availableScalars);
    setScalarsFormulations(formulations);
  }, [subGroupParams, availableScalars]);

  useEffect(() => {
    const scalars = {};
    for (const scalar of techOperationsScalars) {
      if (scalar.techOperationSubGroupId === watchFields.techOperationSubGroupId) {
        scalars[scalar.operationParamId] = scalar;
      }
    }
    setAvailableScalars(scalars);
  }, [techOperationsScalars, watchFields.techOperationSubGroupId]);

  useEffect(() => {
    if (!watchFields.techOperationSubGroupId) {
      setSubGroupParamsSorted([]);
      return;
    }
    fetchSubGroupParams(watchFields.techOperationSubGroupId);
    // eslint-disable-next-line
  }, [
    watchFields.techOperationSubGroupId,
    // DUPLICATE_WHEN_DEPENDS_ON_USE_CALLBACK_WITH_PARAM fetchSubGroupParams
  ]);

  // watchFields.farmLandId changes when:
  // 1) FARM selector gets loaded
  // 2) "Добавить в №3" => F5
  // 3) by DIRECT LINK sent via messenger to "Добавить в №3" page
  const { basicHumidity, basicImpurity } = useHumidityImpurityCalculator(
    watchFields.farmLandId
  );

  useEffect(() => {
    setScalarsArguments((prev) => {
      const absorbedFromForm = {
        CropType_basicHumidity: basicHumidity || 0,
        CropType_basicImpurity: basicImpurity || 0, // WithoutParams Сорность
        Techoperation_fieldSize: watchFields.fieldSize
          ? Number.parseInt(`${watchFields.fieldSize}`, 10) // watchFields.fieldSize содержит строку в runtime
          : 0,
      };
      devlog(`absorbedFromForm, fieldSize:`, absorbedFromForm, watchFields.fieldSize);
      return {
        ...prev,
        ...absorbedFromForm,
      };
    });
  }, [
    basicHumidity,
    basicImpurity, // WithoutParams Сорность
    watchFields.fieldSize,
    isExistingTechOperation,
  ]);

  const getAvailableScalar = useCallback(
    (paramId) => availableScalars[paramId],
    [availableScalars]
  );

  useEffect(() => {
    if (subGroupParams === undefined) {
      return;
    }

    if (Object.keys(scalarsFormulations).length === 0) {
      return;
    }

    if (Object.keys(scalarsArguments).length <= 3) {
      // ещё не отработал useEffect(() => {setScalarsArguments(...)}),
      // после него станет 6 записей, когда появятся - они поучаствуют в вычислениях ниже
      return;
    }

    devlog(`========= NOW_CALCULATING scalarsFormulations`);

    const scalarsArgumentsAndComputedValues: ScalarArguments = { ...scalarsArguments };
    const paramIds = Object.keys(scalarsFormulations);
    for (const paramId of paramIds) {
      const scalar = scalarsFormulations[paramId];
      const formula = scalar.formula;
      const formulaEvaluated = formula.evaluate(scalarsArgumentsAndComputedValues);
      let computed = round(formulaEvaluated, 2);

      // check on wrong values
      if (!isFinite(computed)) {
        computed = 0;
      }

      const prevValue = scalarsArgumentsAndComputedValues[scalar.alias];
      scalarsArgumentsAndComputedValues[scalar.alias] = computed;

      const originalParamFound: ITechOperationParamWithArrayParamsDto | undefined =
        subGroupParams?.find((x) => x.id.toString() === paramId);

      const formulaAsString = ` ${scalar.alias}=[${getAvailableScalar(paramId)
        ?.formulation}]`;

      devlog(
        `computed[${scalar.alias}]: [${prevValue}]=>[${computed}]` +
          `\n        (CropType_basicHumidity: [${scalarsArguments["CropType_basicHumidity"]}],` +
          ` CropType_basicImpurity: [${scalarsArguments["CropType_basicImpurity"]}],` +
          ` Techoperation_fieldSize: [${scalarsArguments["Techoperation_fieldSize"]}])` +
          `\n        formulaAsString: ${formulaAsString}` +
          `\n ${JSON.stringify(scalarsArgumentsAndComputedValues, null, 2)}`,
        scalarsArgumentsAndComputedValues
      );

      let computedAsString = computed.toString();

      const unitsToAppend = computedUnits[scalar.alias];
      if (unitsToAppend !== undefined) {
        computedAsString = `${computed} ${unitsToAppend}`; // UNITS_ADDED_TO_COMPUTED_FIELDS
      }

      // https://tracker.yandex.ru/DEVELOPMENT-3519
      // when disabled & required, don't highlight with red computed value=0
      // (field turn red via shouldValidate:true);
      // for прямое комбайнирование, 3 disabled fields
      // (урожайность, зачётная, вал сбор приведённый) should NOT become red
      // mark red the source fields, not the calculated ones here
      const dontValidateComputed = originalParamFound
        ? originalParamFound.isRequired && isExistingTechOperation
        : true;

      setValue(`params.operationParamId-${paramId}.value`, computedAsString, {
        shouldValidate: disabled ? false : dontValidateComputed,
      });
      // setCalculatedJustOnce(true);
    }
  }, [
    scalarsArguments,
    scalarsFormulations,
    getAvailableScalar,
    subGroupParams,
    isExistingTechOperation,
    setValue,
    disabled,
  ]);

  const onSubGroupParamsFetched = useCallback(() => {
    if (!isExistingTechOperation || !watchFields.params) {
      return;
    }
    for (const param of subGroupParamsSorted) {
      const formulation = availableScalars[param.id]?.formulation;
      if (formulation) {
        continue;
      }

      const paramField = watchFields.params[`operationParamId-${param.id}`];
      if (!paramField) {
        devlog(`AVOIDING_CRASH__paramField_IS_NULL_OR_UNDEFINED`, param);
        continue;
      }

      if (param.techOperationArrayParams.length > 0) {
        const found: ITechOperationArrayParamDto | undefined =
          param.techOperationArrayParams.find((x) => x.id === paramField.arrayParamId);

        const newArrayParamDetails = found?.techOperationArrayParamsDetails || [];
        if (isEqual(arrayParamDetails, newArrayParamDetails)) {
          continue; // avoiding ENDLESS_LOOP
        }
        // hopefully can be just ONE param among subGroupParamsSorted,
        // otherwise what is the expected state of arrayParamDetails?
        // (after have been changed multiple times)
        setArrayParamDetails(newArrayParamDetails);
        if (found) {
          setSubTypeParamTitle(found.subTypeParamTitle);
        }
      } else {
        if (Object.hasOwn(paramField, "value") === false) {
          deverror(`AVOIDING_CRASH__NO_VALUE_IN_paramField`, param);
          continue;
        }
        const valueAsNum: number = +paramField.value;
        setScalarsArguments((prev) => {
          if (prev[param.alias] === valueAsNum) {
            return prev; // avoiding ENDLESS_LOOP
          }
          return {
            ...prev,
            [param.alias]: valueAsNum,
          };
        });
      }
    }
  }, [
    isExistingTechOperation,
    watchFields.params,
    subGroupParamsSorted,
    availableScalars,
    arrayParamDetails,
  ]);

  useEffect(() => {
    onSubGroupParamsFetched();
  }, [
    subGroupParamsSorted,
    availableScalars,
    watchFields.params,
    onSubGroupParamsFetched,
  ]);

  // TODO: hack for sorting field in view (should be changed,
  // maybe replace fields array on object)
  const sortedSubGroupParams = useMemo(() => {
    const asc = [
      "product_type", // WithParams Назначение продукции
      "impurity", // WithoutParams Сорность
      "mass_harvested_products", // WithoutParams Валовый сбор
      "yield_harvest", // WithoutParams Урожайность
      "confirm_yield", // WithoutParams Зачетная урожайность
      "humidity_during_cleaning", // WithoutParams Влажность при уборке
      "mass_harvested_products_reduced_to_standard", // WithoutParams Валовый сбор, приведенный к базовым показателям, т
    ].reverse();
    const sortedArr = subGroupParamsSorted.concat();
    sortedArr.sort((a, b) => {
      return asc.indexOf(a.alias) >= asc.indexOf(b.alias) ? -1 : 1;
    });
    return sortedArr;
  }, [subGroupParamsSorted]); // memo is re-calculated after fetchSubGroupParams()

  const techOperationsSubGroups = useSelector(getInfoTechOperationSubGroups);
  const subGroup = useMemo(
    () =>
      techOperationsSubGroups.find((x) => x.id === watchFields.techOperationSubGroupId),
    [techOperationsSubGroups, watchFields.techOperationSubGroupId]
  );

  if (isLoadingSubGroupParams) {
    return (
      <SpinnerCenter
        minHeight="6vh"
        msg={`Загружаются параметры подтипа '${subGroup?.name || ""}'...`}
      />
    );
  }

  return (
    <Grid container={true} spacing={2}>
      {sortedSubGroupParams.map((param) => (
        <React.Fragment key={param.id}>
          <RHFInputHidden
            name={`params.operationParamId-${param.id}.id`}
            defaultValue={undefined}
          />
          <RHFInputHidden
            name={`params.operationParamId-${param.id}.operationParamId`}
            defaultValue={param.id}
          />

          {param.techOperationArrayParams.length > 0 ? (
            <WithParams
              param={param}
              arrayParamDetailsState={[arrayParamDetails, setArrayParamDetails]}
              subTypeParamTitleState={[subTypeParamTitle, setSubTypeParamTitle]}
              disabled={disabled}
            />
          ) : (
            <WithoutParams
              param={param}
              readOnly={Boolean(availableScalars[param.id]?.formulation) || !!disabled}
              setScalarsArguments={setScalarsArguments}
              formulaAsString={` ${param.alias}=[${availableScalars[param.id]
                ?.formulation}]`}
              initialValue={scalarsArguments[param.alias]}
            />
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
};

// WithParams product_type Назначение продукции
function WithParams({
  param,
  arrayParamDetailsState,
  subTypeParamTitleState,
  disabled,
}: {
  param: ITechOperationParamWithArrayParamsDto;
  arrayParamDetailsState: [
    arrayParamDetails: ITechOperationArrayParamDetailsDto[],
    setArrayParamDetails: React.Dispatch<
      React.SetStateAction<ITechOperationArrayParamDetailsDto[]>
    >,
  ];
  subTypeParamTitleState: [
    subTypeParamTitle: string,
    setSubTypeParamTitle: React.Dispatch<React.SetStateAction<string>>,
  ];
  disabled?: boolean;
}): JSX.Element {
  const [arrayParamDetails, setArrayParamDetails] = arrayParamDetailsState;
  const [subTypeParamTitle, setSubTypeParamTitle] = subTypeParamTitleState;

  const units =
    computedUnits[param.alias] !== undefined ? ", " + computedUnits[param.alias] : "";

  return (
    <>
      <Grid item={true} xs={6}>
        <RHFAutocomplete<ITechOperationArrayParamDto>
          name={`params.operationParamId-${param.id}.arrayParamId`}
          rules={{ required: disabled ? false : param.isRequired }}
          defaultValue={""}
          renderValue={
            (value) => findModelByProperty(param.techOperationArrayParams, value)
            // param.techOperationArrayParams.find((x) => x.id.toString() === value) || null
          }
          renderOnChange={(option: ITechOperationArrayParamDto | null, onChange) => {
            if (option === null) {
              return; // INSERT_BREAKPOINT
            }
            const alreadySelected = isEqual(
              option.techOperationArrayParamsDetails,
              arrayParamDetails
            );
            if (alreadySelected) {
              return; // INSERT_BREAKPOINT
            }
            setArrayParamDetails(option.techOperationArrayParamsDetails);
            setSubTypeParamTitle(option.subTypeParamTitle);
            onChange(option.id);
          }}
          AutocompleteProps={{
            disabled: disabled,
            options: param.techOperationArrayParams,
            getOptionLabel: (option) => option.param || "",
          }}
          TextFieldProps={{
            label: param.title + units,
            required: disabled ? false : param.isRequired,
          }}
        />
      </Grid>

      <Grid item={true} xs={6}>
        {arrayParamDetails.length > 0 && (
          <RHFAutocomplete<ITechOperationArrayParamDetailsDto>
            name={`params.operationParamId-${param.id}.value`}
            rules={{ required: disabled || param.isRequired }}
            defaultValue={""}
            renderValue={(value) =>
              // findModelByProperty(arrayParamDetails, value, "paramEng")
              arrayParamDetails.find((x) => x.paramEng === value) || null
            }
            renderOnChange={(newParamDetails, onChange) => {
              onChange(newParamDetails?.paramEng);
            }}
            AutocompleteProps={{
              disabled: disabled,
              options: arrayParamDetails,
              getOptionLabel: (option) => option.param || "",
              isOptionEqualToValue: (option, val) => option.paramEng === val.paramEng,
            }}
            TextFieldProps={{
              label: subTypeParamTitle,
              required: param.isRequired,
            }}
          />
        )}
      </Grid>
    </>
  );
}

// WithoutParams impurity Сорность
// WithoutParams Валовый сбор
// WithoutParams Урожайность
// WithoutParams confirm_yield WithoutParams Зачетная урожайность
// WithoutParams humidity_during_cleaning Влажность при уборке
// WithoutParams mass_harvested_products_reduced_to_standard Валовый сбор, приведенный к базовым показателям, т
function WithoutParams({
  param,
  readOnly,
  setScalarsArguments,
  formulaAsString,
  initialValue,
}: {
  param: ITechOperationParamWithArrayParamsDto;
  readOnly: boolean;
  setScalarsArguments: React.Dispatch<React.SetStateAction<ScalarArguments>>;
  formulaAsString?: string;
  initialValue?: string | number;
}): JSX.Element {
  const units =
    computedUnits[param.alias] !== undefined
      ? ", " + computedUnits[param.alias] // force multiline
      : "";

  return (
    <Grid item={true} xs={6}>
      <RHFTextField
        name={`params.operationParamId-${param.id}.value`}
        rules={{
          required: readOnly ? false : param.isRequired,
          min: 0.001, // TODO: need to find a step (from params)
        }}
        defaultValue={""}
        renderOnChange={(value, onChange) => {
          setScalarsArguments((prev) => ({
            ...prev,
            [param.alias]: +value,
          }));
          onChange(value);
        }}
        TextFieldProps={{
          type: readOnly ? "text" : "number", // UNITS_ADDED_TO_COMPUTED_FIELDS
          label: param.title + units,
          required: readOnly ? false : param.isRequired,
          disabled: readOnly,
          // may not be needed:
          // inputProps: {
          //   readOnly: readOnly,
          // },
        }}
        title={`ParamId: ${param.id}[${initialValue}] ${formulaAsString}`}
      />
    </Grid>
  );
}

// pure functions below
// yes they are re-created on every component creation / render / whatever;
// but pure functions work the same way and serve to make main code cleaner
// extracted to simplify the logic, can be tested later for a proper output
function sortParams(
  techOpParams: ITechOperationParamWithArrayParamsDto[],
  availableScalars: AvailableScalars
): ITechOperationParamWithArrayParamsDto[] {
  const seen = new Set();
  const result = [...techOpParams].sort((a, _) => {
    const scalar = availableScalars[a.id];
    if (!scalar || Object.values(scalar.paramArgs).every((id) => seen.has(id))) {
      seen.add(a.id);
      return -1;
    }
    return 1;
  });
  return result;
}

function extractFormulations(
  techOpParamsSorted: ITechOperationParamWithArrayParamsDto[],
  availableScalars: AvailableScalars
): IScalarFormulation {
  const formulationsList: IScalarFormulation = {};
  for (const param of techOpParamsSorted) {
    const scalar = availableScalars[param.id];
    if (!scalar || !scalar.formulation) {
      continue;
    }
    const formulation = scalar.formulation;
    const formula = new Formula.Parser<number>(formulation);
    formulationsList[param.id] = {
      alias: param.alias,
      formula: formula,
    };
  }
  return formulationsList;
}
